<script>
export default {
    props: {
        type: {
            type: String,
            default: 'pix'
        }
    },
    data() {
        return {
            phrases: {
                title: {
                    pix: {
                        generatePix: "Pagamento PIX foi gerado! 👀 ",
                        salePix: "Saiu venda no PIX! 🔥",
                    },
                    card: {
                        saleCard: "Teve compra aprovada! 🔥"
                    },
                    withdraw: {
                        withdrawComplete: "O seu saque foi efetuado! 🤑"
                    }
                },
                description: {
                    sale: "Você recebeu R$ 97,00 de comissão!",
                    generatePix: "Pagamento PIX gerado no valor de R$ 97,00",
                    withdraw: "Efetuamos o saque no valor de R$ 47.000,00"
                }
            }
        }
    }
}
</script>

<template>
    <section class="bg-notification">
        <figure>
            <img src="@/assets/svg/logos/flame-rounded-logo.svg" alt="Logo Flame Pay">
        </figure>

        <article class="notification-infos">
            <h2>
                {{ type == 'payPix' ? phrases.title.pix.salePix : type == 'generatePix' ? phrases.title.pix.generatePix : type == 'payCard' ? phrases.title.card.saleCard : type == 'withdraw' ? phrases.title.withdraw.withdrawComplete : '' }}
            </h2>

            <p>
                {{ type == 'payPix' || type == 'payCard' ? phrases.description.sale : type == 'generatePix' ? phrases.description.generatePix : type == 'withdraw' ? phrases.description.withdraw : '' }}
            </p>
        </article>
    </section>
</template>

<style scoped>
.bg-notification {
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    background: rgba(255, 255, 255, 0.07);
    display: flex;
    align-items: center;
    padding: 8px 10px 4px 10px;
    justify-content: flex-start;
    gap: 10px;
    /* border-left: 1px solid #EE135B;
    border-bottom: 1px solid #EE135B; */
    user-select: none;
}

.bg-notification .notification-infos h2 {
    color: #FFF;
    font-family: var(--font-work-sans);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.24px;
}

.bg-notification .notification-infos p {
    color: #BFBFBF;
    font-family: var(--font-work-sans);
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: -0.16px;
    margin-bottom: 5px;
}
</style>