<script>
export default {
    props: {
        rounded: {
            type: Number,
            default: 100
        },
        colorful: {
            type: Boolean,
            default: true
        },
        text: {
            type: String
        }
    },
    methods: {
        emitClick() {
            return this.$emit('click', true);
        }
    }
}
</script>

<template>
    <button :class="[
        colorful ? 'colorful' : 'none-colorful',
        rounded == 100 ? 'rounded-100' : 'rounded-none',
        'bg-button'
    ]">
        {{ text }}
    </button>
</template>

<style scoped>
button {
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: var(--font-work-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.32px;
    cursor: pointer;
}

.colorful {
    color: #FFF;
    background-color: rgba(144, 144, 144, 0.07);
    background-image: linear-gradient(90deg, #ee135b 0%, #f84983 50%, #ee135b 90%);
    background-position: 100% 0;
    background-size: 200% 200%;
    transition: all 0.4s ease;
    border: 1px solid var(--Linear, #F84983);
    backdrop-filter: blur(2.6500000953674316px);
}

.colorful:hover {
    background-image: linear-gradient(90deg, #ee135b 50%, #f84983 70%, #ee135b 90%);
    box-shadow: 0 0px 0px 0 rgba(0, 40, 120, 0);
    background-position: 0 0;
}

.none-colorful {
    background-color: rgba(144, 144, 144, 0.07);
    background-image: linear-gradient(90deg, #f84983 0%, #ee135b 25%, rgba(144, 144, 144, 0.07) 50%, rgba(144, 144, 144, 0.07) 90%);
    background-position: 100% 0;
    background-size: 200% 200%;
    transition: all 0.4s ease;
    border: 1px solid var(--Linear, #F84983);
    /* background: rgba(144, 144, 144, 0.07); */
    backdrop-filter: blur(2.6500000953674316px);
    color: #FFF;
}

.none-colorful:hover {
    background-image: linear-gradient(90deg, #f84983 0%, #ee135b 50%, rgba(144, 144, 144, 0.07) 90%);
    box-shadow: 0 0px 0px 0 rgba(0, 40, 120, 0);
    background-position: 0 0;
}

.rounded-100 {
    border-radius: 100px;
}
</style>