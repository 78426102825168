<template>
  <router-view/>
</template>

<style>
/* Fonts Import */
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Space+Grotesk:wght@300..700&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

/* Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

img,
button,
.none-select {
  user-select: none;
}

::selection {
  background-color: var(--principal-color);
  color: #FFF;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: #1f1f1f;
}

body::-webkit-scrollbar-thumb {
  background-color: #EE135B;
  border-radius: 20px;
  border: 2px solid #1f1f1f;
}

/* Variables */
:root {
  /* Background colors */
  --primary-gradient-bg: linear-gradient(90deg, rgba(248,73,131,1) 0%, rgba(238,19,91,1) 100%);

  /* Background images */
  --space-bg-image: url('@/assets/svg/backgrounds/space-background.svg');

  /* Background Fonts */
  --primary-font-bg: linear-gradient(90deg, #FFFFFF 0%, #BEB1B1 100%);

  /* Fonts */
  --font-work-sans: "Work Sans";
  --font-space-grotesk: "Space Grotesk";
  --font-montserrat: "Montserrat";

  /* Hex Colors */
  --principal-color: #EE135B;
}

/* Defaults */
body {
  background-color: #050002;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.bg {
  width: 100%;
}

.container {
  max-width: 1228px;
  margin: 0 auto;
  padding: 0px 0px;
}

.px-20 {
  padding: 0px 20px;
}
</style>
