<script>
export default {
    methods: {
        emitClick() {
            return this.$emit('click', true);
        }
    }
}
</script>

<template>
    <section @click="emitClick" class="bg-alert">
        <p>Fatura mais de 10k por mês? Temos uma oferta especial para você. Clique aqui!</p>
    </section>
</template>

<style scoped>
    .bg-alert {
        width: 100%;
        padding: 12px;
        background-image: var(--primary-gradient-bg);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .bg-alert p {
        color: #FFF;
        text-align: center;
        font-family: var(--font-work-sans);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.28px;
    }
</style>