<script>
import SpecialOfferAlert from '@/components/SpecialOfferAlert.vue';
import NavBar from '@/components/NavBar.vue';
import Button from '@/components/Button.vue';
import Notification from '@/components/Notification.vue'
import WaitingList from '@/components/WaitingList.vue';
import Donate from '@/components/Donate.vue';

export default {
  data() {
    return {
      notifications: [
        { id: 1, type: "generatePix" },
        { id: 2, type: "payPix" },
        { id: 3, type: "generatePix" },
        { id: 4, type: "payCard" },
        { id: 5, type: "payPix" },
        { id: 6, type: "withdraw" },
        { id: 7, type: "payCard" },
        { id: 8, type: "payPix" },
      ],
      visibleNotificationsCount: 5,
      enableWaitingListView: false,
      enableDonateView: false,
      loader: false,
      createEmail: ''
    }
  },
  computed: {
    visibleNotifications() {
      return this.notifications.slice(0, this.visibleNotificationsCount);
    }
  },
  methods: {
    redirectToDonate() {
      window.open('https://pay.kiwify.com.br/DAzNlqc', '_blank');
    },
    redirectToSocial(social) {
      if (social == 'instagram') {
        window.open('https://www.instagram.com/flamepayoficial', '_blank');
      }

      if (social == 'facebook') {
        window.open('https://www.instagram.com/flamepayoficial', '_blank');
      }

      if (social == 'linkedin') {
        window.open('https://www.linkedin.com/company/flame-pay', '_blank');
      }
    },
    shiftNotifications() {
      // Remove a primeira notificação e adiciona no final
      const firstNotification = this.notifications.shift();
      this.notifications.push(firstNotification);
    },
    notificationStyle(index) {
      // Retorna o estilo baseado na posição
      const marginStyles = [
        { marginLeft: "0px" },
        { marginLeft: "-60px" },
        { marginLeft: "-30px" },
        { marginLeft: "0px" },
        { marginLeft: "-64px" }
      ];
      return {
        transition: "all 0.8s ease-in-out",
        transform: `translateY(${index * 0}%)`,
        ...marginStyles[index]
      };
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    validateEmail(email) {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return regex.test(email);
    },
    registerUserMail(email) {
      if (email.length <= 0 || !this.validateEmail(email)) {
        return this.$notification('O e-mail deve ser um e-mail válido!', 'error');
      }

      if (!this.loader) {
        this.loader = true;

        this.enableWaitingListView = false;
        this.$notification('Seu e-mail foi cadastrado com sucesso!', 'success');

        this.$axios.post('https://divulgacerto.com', {
          email: email
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          }
        },)
        .then(res => {
          console.log('cadastrou');
          this.loader = false;
          this.enableDonateView = true;
          this.enableWaitingListView = false;
        })
        .catch(err => {
          console.log('não cadastrou');
          this.loader = false;
          this.enableDonateView = true;
          this.enableWaitingListView = false;
        });
      }
    }
  },
  mounted() {
    setInterval(this.shiftNotifications, 3000);

    const sections = document.querySelectorAll('.fade-in-section');
    
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-visible');
          observer.unobserve(entry.target); // Para observar apenas uma vez
        }
      });
    }, { threshold: 0.1 }); // Quando 10% da seção está visível

    sections.forEach(section => {
      observer.observe(section);
    });
  },
  components: { SpecialOfferAlert, NavBar, Button, Notification, WaitingList, Donate }
}
</script>

<template>
  <WaitingList v-if="enableWaitingListView" @post="registerUserMail" @close="enableWaitingListView = false" />
  <Donate v-if="enableDonateView" @donate="redirectToDonate" @close="enableDonateView = false" />

  <section class="bg">
    <SpecialOfferAlert @click="enableWaitingListView = true" />
  </section>

  <header class="header-page">
    <section class="container">
      <div class="px-20 fade-in-section">
        <NavBar @clickUserAction="enableWaitingListView = true" @behaviorTo="scrollToSection" />
      </div>

      <section class="bg-content-header">
        <article class="bg-content px-20 fade-in-section">
          <h1>Transforme Seu Conhecimento Em Renda Com Um Checkout De Alta Conversão!</h1>

          <p>Aproveite uma plataforma criada para aumentar suas vendas, com checkout otimizado, pixeis inteligentes e uma área de membros exclusiva.</p>

          <div class="action-button">
            <Button @click="enableWaitingListView = true" text="Quero começar a vender" />
          </div>

          <section class="total-datas">
            <div class="data">
              <h3>10.000 <span>+</span></h3>
              <p>Pessoas vendendo</p>
            </div>

            <div class="data">
              <h3>R$ 25M <span>+</span></h3>
              <p>Transacionados</p>
            </div>
            <div></div>
          </section>
        </article>
        

        <figure class="bg-figure fade-in-section">
          <img src="@/assets/svg/illustrations/phone-notifications-header.svg" alt="Flame Pay">

          <section class="bg-notifications">
            <div class="notifications">
              <transition-group name="notification" tag="div">
                <div v-for="(notification, index) in visibleNotifications"
                  :key="notification.id"
                  class="notification"
                  :style="notificationStyle(index)">
                  <Notification :type="notification.type" />
                </div>
              </transition-group>
            </div>
          </section>
        </figure>
      </section>
    </section>
  </header>
  
  <section class="bg-four-steps">
    <div class="bubbleblur">
        <img src="@/assets/svg/illustrations/bubbleblur.svg" alt="">
    </div>

    <section class="four-steps-to-sale container px-20">
      <article>
        <h2 class="fade-in-section">4 passos que te separam da sua Primeira Venda</h2>

        <section class="steps">
          <!-- Crie sua conta -->
          <div class="step fade-in-section">
            <figure class="step-figure">
              <img src="@/assets/svg/illustrations/four-steps/step-one.svg" alt="Crie sua conta na Flame Pay">
            </figure>

            <section class="step-article">
              <h3 class="register-account">
                Crie sua conta

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>
              </h3>
              <p>Preencha Nome, E-mail e Senha em nosso formulário de cadastro, e em menos de 1 minuto a sua conta está criada.</p>
            </section>
          </div>
          
          <!-- Confirme Os Dados -->
          <div class="step fade-in-section">
            <figure class="step-figure">
              <img src="@/assets/svg/illustrations/four-steps/step-two.svg" alt="confirme seus dados na Flame Pay">
            </figure>

            <section class="step-article">
              <h3>Confirme os dados</h3>
              <p>Faça o envio dos documentos solicitados para a liberação das vendas.</p>
            </section>
          </div>

          <!-- Crie seus produtos -->
          <div class="step fade-in-section">
            <figure class="step-figure">
              <img src="@/assets/svg/illustrations/four-steps/step-three.svg" alt="Crie seus produtos na Flame Pay">
            </figure>

            <section class="step-article">
              <h3>Crie seus produtos</h3>
              <p>Cadastre seus produtos e ofertas, personalize o checkout do seu jeito e comece e comece a rodar anuncios.</p>
            </section>
          </div>

          <!-- Comece a vender -->
          <div class="step fade-in-section">
            <figure class="step-figure">
              <img src="@/assets/svg/illustrations/four-steps/step-four.svg" alt="Comece a vender na Flame Pay">
            </figure>

            <section class="step-article">
              <h3>Comece a vender</h3>
              <p>Agora você é oficialmente um Flamer, basta começar a vender e ver a mágica acontecer!</p>
            </section>
          </div>
        </section>
      </article>

      <figure class="future-people fade-in-section">
        <img src="@/assets/png/illustrations/future-people.png" alt="Mais de 10 mil produtores já vendem na Flame Pay">
      </figure>
    </section>
  </section>

  <section id="aprenda-a-vender" class="learn-sell-bg container px-20 fade-in-section">
    <section class="learn-sell">
      <section class="bg">
        <header>
          <h3>Não sabe vender?</h3>
          <h2>Aprenda gratuitamente!</h2>
        </header>

        <article>
          <p>Comece a sua jornada no marketing digital com nosso curso gratuito, que ensina desde as bases até estratégias avançadas para vender mais pela internet</p>
        </article>

        <div class="button">
          <Button text="Acessar curso gratuito" @click="enableWaitingListView = true" />
        </div>
      </section>
      </section>
  </section>

  <section class="why-us-bg container">
    <div class="rounded-red-blur">
      <svg xmlns="http://www.w3.org/2000/svg" width="756" height="756" viewBox="0 0 756 756" fill="none">
        <g opacity="0.6" filter="url(#filter0_f_3_484)">
          <circle cx="377.977" cy="377.977" r="114.977" fill="#FF0054"/>
        </g>
        <defs>
          <filter id="filter0_f_3_484" x="0.899994" y="0.899994" width="754.155" height="754.155" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="131.05" result="effect1_foregroundBlur_3_484"/>
          </filter>
        </defs>
      </svg>
    </div>

    <div class="wave">
      <img src="@/assets/svg/illustrations/wave.svg" alt="Flame Pay">
    </div>

    <section id="porque-nos" class="why-us">
      <h2 class="fade-in-section">Por que somos a melhor opção?</h2>

      <section class="cards-list">
        <div class="card fade-in-section">
          <div class="rounded-gradient">
            <svg xmlns="http://www.w3.org/2000/svg" width="373" height="330" viewBox="0 0 373 330" fill="none">
              <g opacity="0.6" filter="url(#filter0_f_3_458)">
                <circle cx="310.506" cy="309.411" r="82.0603" fill="url(#paint0_linear_3_458)"/>
              </g>
              <defs>
                <filter id="filter0_f_3_458" x="0.345306" y="-0.749664" width="620.321" height="620.321" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feGaussianBlur stdDeviation="114.05" result="effect1_foregroundBlur_3_458"/>
                </filter>
                <linearGradient id="paint0_linear_3_458" x1="235.823" y1="343.273" x2="457.519" y2="327.425" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#F84983"/>
                  <stop offset="1" stop-color="#EE135B"/>
                </linearGradient>
              </defs>
            </svg>
          </div>

          <header>
            <h3>Checkout de alta conversão</h3>

            <div class="figure">
            <div class="rounded-purple-blur"></div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z" />
            </svg>
            </div>
          </header>

          <article class="card-info">
            <p>Moderno, seguro e otimizado para aumentar suas vendas.</p>
            <p class="subtitle">Checkout Personalizável</p>
          </article>
        </div>
        
        <div class="card fade-in-section">
          <div class="rounded-gradient">
            <svg xmlns="http://www.w3.org/2000/svg" width="373" height="330" viewBox="0 0 373 330" fill="none">
              <g opacity="0.6" filter="url(#filter0_f_3_458)">
                <circle cx="310.506" cy="309.411" r="82.0603" fill="url(#paint0_linear_3_458)"/>
              </g>
              <defs>
                <filter id="filter0_f_3_458" x="0.345306" y="-0.749664" width="620.321" height="620.321" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feGaussianBlur stdDeviation="114.05" result="effect1_foregroundBlur_3_458"/>
                </filter>
                <linearGradient id="paint0_linear_3_458" x1="235.823" y1="343.273" x2="457.519" y2="327.425" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#F84983"/>
                  <stop offset="1" stop-color="#EE135B"/>
                </linearGradient>
              </defs>
            </svg>
          </div>

          <header>
            <h3>Área de membros exclusiva</h3>

            <div class="figure">
              <div class="rounded-purple-blur"></div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3" />
              </svg>
            </div>
          </header>

          <article class="card-info">
            <p>Hospede vídeos em até 4K em uma Área de Membros Super Premium.</p>
            <p class="subtitle">Experiência Única</p>
          </article>
        </div>

        <div class="card fade-in-section">
          <div class="rounded-gradient">
            <svg xmlns="http://www.w3.org/2000/svg" width="373" height="330" viewBox="0 0 373 330" fill="none">
              <g opacity="0.6" filter="url(#filter0_f_3_458)">
                <circle cx="310.506" cy="309.411" r="82.0603" fill="url(#paint0_linear_3_458)"/>
              </g>
              <defs>
                <filter id="filter0_f_3_458" x="0.345306" y="-0.749664" width="620.321" height="620.321" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feGaussianBlur stdDeviation="114.05" result="effect1_foregroundBlur_3_458"/>
                </filter>
                <linearGradient id="paint0_linear_3_458" x1="235.823" y1="343.273" x2="457.519" y2="327.425" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#F84983"/>
                  <stop offset="1" stop-color="#EE135B"/>
                </linearGradient>
              </defs>
            </svg>
          </div>

          <header>
            <h3>Gerente desde o momento zero</h3>

            <div class="figure">
            <div class="rounded-purple-blur"></div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002" />
            </svg>
            </div>
          </header>

          <article class="card-info">
            <p>Tenha um gerente exclusivo desde o primeiro dia, dedicado a te atender.</p>
            <p class="subtitle">Suporte acertivo</p>
          </article>
        </div>

        <div class="card fade-in-section">
          <div class="rounded-gradient">
            <svg xmlns="http://www.w3.org/2000/svg" width="373" height="330" viewBox="0 0 373 330" fill="none">
              <g opacity="0.6" filter="url(#filter0_f_3_458)">
                <circle cx="310.506" cy="309.411" r="82.0603" fill="url(#paint0_linear_3_458)"/>
              </g>
              <defs>
                <filter id="filter0_f_3_458" x="0.345306" y="-0.749664" width="620.321" height="620.321" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feGaussianBlur stdDeviation="114.05" result="effect1_foregroundBlur_3_458"/>
                </filter>
                <linearGradient id="paint0_linear_3_458" x1="235.823" y1="343.273" x2="457.519" y2="327.425" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#F84983"/>
                  <stop offset="1" stop-color="#EE135B"/>
                </linearGradient>
              </defs>
            </svg>
          </div>

          <header>
            <h3>Pixels super inteligentes</h3>

            <div class="figure">
            <div class="rounded-purple-blur"></div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
            </svg>
            </div>
          </header>

          <article class="card-info">
            <p>Melhore suas campanhas com o nosso trackeamento avançado.</p>
            <p class="subtitle">Melhor trackeamento</p>
          </article>
        </div>

        <div class="card fade-in-section">
          <div class="rounded-gradient">
            <svg xmlns="http://www.w3.org/2000/svg" width="373" height="330" viewBox="0 0 373 330" fill="none">
              <g opacity="0.6" filter="url(#filter0_f_3_458)">
                <circle cx="310.506" cy="309.411" r="82.0603" fill="url(#paint0_linear_3_458)"/>
              </g>
              <defs>
                <filter id="filter0_f_3_458" x="0.345306" y="-0.749664" width="620.321" height="620.321" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feGaussianBlur stdDeviation="114.05" result="effect1_foregroundBlur_3_458"/>
                </filter>
                <linearGradient id="paint0_linear_3_458" x1="235.823" y1="343.273" x2="457.519" y2="327.425" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#F84983"/>
                  <stop offset="1" stop-color="#EE135B"/>
                </linearGradient>
              </defs>
            </svg>
          </div>

          <header>
            <h3>Aprovação imediata de produtos</h3>

            <div class="figure">
            <div class="rounded-purple-blur"></div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122" />
            </svg>
            </div>
          </header>

          <article class="card-info">
            <p>Seu produto é aprovado no mesmo instante que é criado.</p>
            <p class="subtitle">Agilidade no processo</p>
          </article>
        </div>

        <div class="card fade-in-section">
          <div class="rounded-gradient">
            <svg xmlns="http://www.w3.org/2000/svg" width="373" height="330" viewBox="0 0 373 330" fill="none">
              <g opacity="0.6" filter="url(#filter0_f_3_458)">
                <circle cx="310.506" cy="309.411" r="82.0603" fill="url(#paint0_linear_3_458)"/>
              </g>
              <defs>
                <filter id="filter0_f_3_458" x="0.345306" y="-0.749664" width="620.321" height="620.321" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feGaussianBlur stdDeviation="114.05" result="effect1_foregroundBlur_3_458"/>
                </filter>
                <linearGradient id="paint0_linear_3_458" x1="235.823" y1="343.273" x2="457.519" y2="327.425" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#F84983"/>
                  <stop offset="1" stop-color="#EE135B"/>
                </linearGradient>
              </defs>
            </svg>
          </div>
          
          <header>
            <h3>Recuperação de vendas</h3>

            <div class="figure">
            <div class="rounded-purple-blur"></div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9.75h4.875a2.625 2.625 0 0 1 0 5.25H12M8.25 9.75 10.5 7.5M8.25 9.75 10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185Z" />
            </svg>
            </div>
          </header>

          <article class="card-info">
            <p>Recupere vendas não finalizadas e checkouts abandonados.</p>
            <p class="subtitle">Maior conversão</p>
          </article>
        </div>
      </section>

      <div class="button fade-in-section">
        <Button @click="enableWaitingListView = true" text="Começar minha operação" />
      </div>
    </section>
  </section>

  <section id="premios" class="awards-bg container">
    <h2 class="fade-in-section">
      Conclua Metas e Receba Prêmios!
    </h2>

    <section class="awards-list">
      <div class="award fade-in-section">
        <figure>
          <img src="@/assets/svg/illustrations/awards/zerofaturamento.svg" alt="Curso gratuito de Marketing Digital Flame Pay">
        </figure>

        <article>
          <h3>FATURAMENTO ZERO</h3>

          <p>Treinamento gratuito de como começar a vender pela internet</p>
        </article>
      </div>

      <div class="award fade-in-section">
        <figure>
          <img src="@/assets/svg/illustrations/awards/10kfaturamento.svg" alt="Curso gratuito de Marketing Digital Flame Pay">
        </figure>

        <article>
          <h3>10K DE FATURAMENTO</h3>

          <p>Consultoria “Como escalar seu negócio” + Pulseira Premium</p>
        </article>
      </div>

      <div class="award fade-in-section">
        <figure>
          <img src="@/assets/svg/illustrations/awards/10kfaturamento.svg" alt="Curso gratuito de Marketing Digital Flame Pay">
        </figure>

        <article>
          <h3>100K DE FATURAMENTO</h3>

          <p>Box de guloseimas + Placa de premiação</p>
        </article>
      </div>

      <div class="award fade-in-section">
        <figure>
          <img src="@/assets/svg/illustrations/awards/10kfaturamento.svg" alt="Curso gratuito de Marketing Digital Flame Pay">
        </figure>

        <article>
          <h3>500K DE FATURAMENTO</h3>

          <p>Kit Flame + Box de guloseimas + Placa de premiação</p>
        </article>
      </div>

      <div class="award fade-in-section">
        <figure>
          <img src="@/assets/svg/illustrations/awards/10kfaturamento.svg" alt="Curso gratuito de Marketing Digital Flame Pay">
        </figure>

        <article>
          <h3>1M DE FATURAMENTO</h3>

          <p>Flame Experience + Box de guloseimas + Placa de premiação</p>
        </article>
      </div>

      <div class="award fade-in-section">
        <figure>
          <img src="@/assets/svg/illustrations/awards/10kfaturamento.svg" alt="Curso gratuito de Marketing Digital Flame Pay">
        </figure>

        <article>
          <h3>10M DE FATURAMENTO</h3>

          <p>Viagem Internacional + Box de guloseimas + Placa de premiação</p>
        </article>
      </div>
    </section>

    <div class="button fade-in-section">
        <Button @click="enableWaitingListView = true" text="Quero vender e receber meus prêmios" />
      </div>
  </section>

  <section id="taxas" class="tax-withdrawal-bg">
    <section class="tax-withdrawal-second-bg container">
      <h2 class="fade-in-section">Taxas e Saque</h2>

      <section class="bg-cards">
        <section class="four-cards">
          <div class="card fade-in-section">
            <h3>Vendas PIX</h3>
            <h2>0% + R$ 2,99</h2>

            <p>Para vendas feitas no PIX, são cobrados R$ 2,99 por transação.</p>
          </div>
          
          <div class="card fade-in-section">
            <h3>Vendas Cartão</h3>
            <h2>4,49% + R$ 2,99</h2>

            <p>Vendas realizadas no Cartão de Crédito são cobrados 4,49% + 2,99 por transação.</p>
          </div>

          <div class="card fade-in-section">
            <h2>D+1 - Vendas PIX</h2>

            <p>Vendas feitas através do PIX ficam disponíveis para saque até um dia útil após a confirmação do pagamento.</p>
          </div>

          <div class="card fade-in-section">
            <h2>D+15 - Vendas Cartão</h2>

            <p>Vendas feitas através do Cartão de Crédito ficam disponíveis para saque até 15 dias úteis após a confirmação.</p>
          </div>
        </section>

        <div class="withdrawal-politic fade-in-section">
          <h3>
            Políticas de saque
          </h3>

          <p>
            Todos os saques realizados em dias úteis são processados no mesmo dia. Saques solicitados em feriados ou finais de semana serão processados no próximo dia útil.
          </p>

          <p>
            Todos os saques são realizados para a conta bancária cadastrada, através do método de pagamento PIX.
          </p>

          <p>
            Os saques estão sujeitos a uma taxa de saque de R$ 2,50.
          </p>
        </div>
      </section>
    </section>
  </section>

  <section class="download-app-bg container">
    <section class="infos">
      <h2 class="fade-in-section">Baixe nosso aplicativo</h2>

      <h3 class="fade-in-section">Receba notificações de vendas e saques</h3>

      <p class="fade-in-section">No nosso aplicativo, você tem total controle sobre as informações da notificação. Ative ou desative Nome do Produto, Comissão e até se quer ou não receber notificações.</p>

      <div class="download-buttons fade-in-section">
        <img src="@/assets/svg/illustrations/mobile-stores/google-play.svg" alt="Baixe a Flame Pay na Google Play">
        <img class="app-store" src="@/assets/svg/illustrations/mobile-stores/app-store.svg" alt="Baixe a Flame Pay na Google Play">
      </div>
    </section>

    <figure class="fade-in-section">
      <img src="@/assets/svg/illustrations/download-app-banner.svg" alt="Baixe o aplicativo da Flame Pay">
    </figure>
  </section>

  <footer class="footer container">
    <section class="top">
      <section class="left">
        <h2 class="fade-in-section">Venha fazer parte, Venha ser Flamer!</h2>
        
        <div class="input-subscription fade-in-section">
          <input type="email" placeholder="Seu endereço de e-mail">

          <div @click="registerUserMail(createEmail)" class="send-button">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
            </svg>
          </div>
        </div>
      </section>

      <section class="right">
        <nav>
          <h3 class="fade-in-section">
            Plataforma
          </h3>

          <router-link class="fade-in-section" to="/termos-de-uso">
            <p>Termos de Uso</p>
          </router-link>
          <router-link class="fade-in-section" to="/politica-de-conteudo">
            <p>Política de Conteúdo</p>
          </router-link>
          <router-link class="fade-in-section" to="/politica-de-privacidade">
            <p>Política de Privacidade</p>
          </router-link>
          <router-link class="fade-in-section" to="/termos-de-premiacao">
            <p>Política de Premiações</p>
          </router-link>
        </nav>

        <nav>
          <h3 class="fade-in-section">
            Suporte
          </h3>

          <router-link class="fade-in-section" to="/politica-de-conteudo">
            <p>Suporte</p>
          </router-link>
          <router-link class="fade-in-section" to="/minhas-compras">
            <p>Minhas Compras</p>
          </router-link>
          <router-link class="fade-in-section" to="">
            <p>Central de Ajudas</p>
          </router-link>
        </nav>
      </section>
    </section>

    <section class="bottom">
      <section class="enterprise-infos">
        <p class="fade-in-section">Todos os direitos reservados à Flame Pay Servicos Digitais LTDA &copy 2024</p>
        <p class="fade-in-section">CNPJ 54.108.567/0001-05</p>
      </section>

      <section class="social-infos fade-in-section">
        <div class="social" @click="redirectToSocial('instagram')">
          <img src="@/assets/svg/icons/social-medias/instagram.svg" alt="Instagram - @flamepayoficial">
        </div>
        <div class="social" @click="redirectToSocial('facebook')">
          <img src="@/assets/svg/icons/social-medias/facebook.svg" alt="Facebook - @flamepayoficial">
        </div>
        <div class="social" @click="redirectToSocial('linkedin')">
          <img src="@/assets/svg/icons/social-medias/linkedin.svg" alt="LinkedIn - https://www.linkedin.com/company/flame-pay">
        </div>
      </section>
    </section>
  </footer>
</template>

<style scoped>
/* Header */
.header-page {
  width: 100%;
  background-image: var(--space-bg-image);
  background-repeat: repeat-y;
  background-size: cover;
  background-position: center;
  padding: 20px 0px;
}

.header-page .bg-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.header-page .bg-content-header .bg-content {
  display: flex;
  flex-direction: column;
  gap: 36px;
  max-width: 505px;
}

.header-page .bg-content-header .bg-content h1 {
  font-family: var(--font-space-grotesk);
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -0.8px;
  text-transform: capitalize;
  background: var(--primary-font-bg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: -50px;
}

.header-page .bg-content-header .bg-content p {
  color: #AFAEC6;
  font-family: var(--font-work-sans);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.36px;
}

.header-page .bg-content-header .bg-content .action-button {
  width: 250px;
  height: 55px;
}

.header-page .bg-content-header .bg-content .total-datas {
  display: flex;
  align-items: flex-start;
  gap: 90px;
}

.header-page .bg-content-header .bg-content .total-datas .data h3 {
  color: #FFF;
  font-family: var(--font-space-grotesk);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.48px;
  text-transform: capitalize;
}

.header-page .bg-content-header .bg-content .total-datas .data span {
  color: var(--principal-color);
}

.header-page .bg-content-header .bg-figure {
  position: relative;
  width: 652px;
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-page .bg-content-header .bg-figure img  {
  width: 800px;
}

.header-page .bg-content-header .bg-figure .bg-notifications {
  position: absolute;
  right: -50px;
}

.header-page .bg-content-header .bg-figure .bg-notifications .notifications {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 14px;
  height: 360px;
  overflow: hidden;
  width: 344px;
}

.header-page .bg-content-header .bg-figure .bg-notifications .notification {
  width: 280px;
  margin: 10px 0px;
}

.notification-enter-active, .notification-leave-active {
  transition: transform 0.8s ease;
}

.notification-enter, .notification-leave-to {
  transform: translateY(-100%);
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20px); /* Vem de baixo */
  transition: opacity 1s ease-out, transform 1s ease-out; /* Animação de transição */
  will-change: opacity, transform;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: translateY(0); /* Sobe para a posição correta */
}

/* Four Steps to Sale */
.bubbleblur {
  position: absolute;
  z-index: 1;
  right: 0px;
}

.four-steps-to-sale {
  width: 100%;
  padding: 80px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.four-steps-to-sale article {
  padding-right: 30px;
  position: relative;
  z-index: 2;
}

.four-steps-to-sale article h2 {
  background: var(--primary-font-bg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--font-space-grotesk);
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -0.72px;
}

.four-steps-to-sale article .steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  max-width: 600px;
}

.four-steps-to-sale article .steps .step {
  width: 100%;
  border-top: 1px solid rgb(43, 43, 43);
  padding: 30px 0px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.four-steps-to-sale article .steps .step .step-article h3 {
  color: #FFF;
  font-family: "Space Grotesk";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: -0.48px;
  text-transform: capitalize;
}

.four-steps-to-sale article .steps .step .step-article .register-account {
  color: var(--principal-color);
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.four-steps-to-sale article .steps .step .step-article .register-account .icon {
  width: 20px;
}

.four-steps-to-sale article .steps .step .step-article p {
  color: #AFAEC6;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: -0.36px;
}

/* Learn Sell */
.learn-sell-bg {
  width: 100%;
  background-image: url('@/assets/svg/backgrounds/lines-background-1.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 80px 20px;
}

.learn-sell-bg .learn-sell {
  width: 100%;
  height: 475px;
  background-image: url('@/assets/svg/backgrounds/pink-waves.svg');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;

}

.learn-sell-bg .learn-sell .bg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(130, 14, 78, 0.404);
  border-radius: 20px;
  gap: 35px;
}

.learn-sell-bg .learn-sell .bg header {
  text-align: center;
  font-family: var(--font-space-grotesk);
  font-size: 20px;
  font-style: normal;
  letter-spacing: -0.64px;
  color: #FFF;
}

.learn-sell-bg .learn-sell .bg header h3 {
  font-size: 24px;
  font-weight: 500;
}

.learn-sell-bg .learn-sell .bg header h2 {
  font-size: 28px;
  font-weight: 500;
}

.learn-sell-bg .learn-sell .bg article {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.learn-sell-bg .learn-sell .bg article p {
  color: #f0f0f0;
  text-align: center;
  font-family: var(--font-work-sans);
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: -0.36px;
  width: 684px;
  max-width: 95%;
}

.learn-sell-bg .learn-sell .bg .button {
  width: 300px;
  max-width: 95%;
  height: 55px;
}

/* Why Us */
.why-us-bg {
  width: 100%;
  padding: 80px 20px;
  position: relative;
}

.why-us-bg .rounded-red-blur {
  fill: var(--6645-eb, #FF0054);
  opacity: 1;
  filter: blur(15.0500030517578px);
  position: absolute;
  top: -200px;
  left: -200px;
  z-index: 1;
}

.why-us-bg .wave {
  position: absolute;
  right: 60px;
  top: 510px;
}

.why-us-bg .why-us {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 80px;
}

.why-us-bg .why-us h2 {
  color: #FFF;
  text-align: center;
  font-family: var(--font-space-grotesk);
  font-size: 28px;
  font-style: normal;
  font-weight: 450;
  line-height: 50px;
  letter-spacing: -0.8px;
}

.why-us-bg .why-us .cards-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.why-us-bg .why-us .cards-list .card {
  width: 390px;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(68, 18, 132, 0.10);
  backdrop-filter: blur(2.6500000953674316px);
  padding: 30px;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.why-us-bg .why-us .cards-list .card .rounded-gradient {
  fill: var(--Linear, linear-gradient(86deg, #F84983 6.15%, #EE135B 132.87%));
  opacity: 0;
  filter: blur(60px);
  width: 165px;
  height: 165px;
  position: absolute;
  transition: .2s;
}

.why-us-bg .why-us .cards-list .card:hover .rounded-gradient {
  opacity: 1;
}

.why-us-bg .why-us .cards-list .card header {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.why-us-bg .why-us .cards-list .card header h3 {
  color: #FFF;
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.4px;
  text-transform: capitalize;
  max-width: 200px;
}

.why-us-bg .why-us .cards-list .card .figure {
  width: 65px;
  height: 65px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: 0.4s;
}

.why-us-bg .why-us .cards-list .card .figure .rounded-purple-blur {
  border-radius: 22.082px;
  background: #6645EB;
  filter: blur(14.25px);
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: -15px;
  transition: .3s;
}

.why-us-bg .why-us .cards-list .card .figure .icon {
  width: 30px;
  color: var(--principal-color);
  transition: .3s;
}

.why-us-bg .why-us .cards-list .card:hover .rounded-purple-blur {
  opacity: 0;
}

.why-us-bg .why-us .cards-list .card:hover .figure {
  background-color: var(--principal-color);
  transition: 0.8s;
}

.why-us-bg .why-us .cards-list .card:hover .figure .icon {
  color: #FFF;
  transition: .8s;
}

.why-us-bg .why-us .cards-list .card .card-info {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 50px;
  border-top: 1px solid #ffffff1f;
}

.why-us-bg .why-us .cards-list .card .card-info p {
  color: var(--afaec-6, #AFAEC6);
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.36px;
}

.why-us-bg .why-us .cards-list .card .card-info .subtitle {
  color: #FFF;
}

.why-us-bg .why-us .button {
  width: 370px;
  height: 55px;
}

/* Awards */
.awards-bg {
  width: 100%;
  padding: 80px 20px;
  background-image: url('@/assets/svg/backgrounds/lines-rounded-blur.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.awards-bg h2 {
  color: #FFF;
  text-align: center;
  font-family: var(--font-space-grotesk);
  font-size: 28px;
  font-style: normal;
  font-weight: 450;
  line-height: 50px;
  letter-spacing: -0.8px;
}

.awards-bg .awards-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 30px;
}

.awards-bg .awards-list .award {
  width: 390px;
  max-width: 100%;
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(152, 95, 226, 0.086);
  backdrop-filter: blur(8.75px);
  padding: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.awards-bg .awards-list .award figure {
  padding: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.09);
  border-bottom: 0px;
  border-radius: 18px 18px 0px 0px;
  height: 220px;
}

.awards-bg .awards-list .award figure img {
  max-width: 100%;
}

.awards-bg .awards-list .award article {
  width: 100%;
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: rgba(0, 0, 0, 0.003);
  backdrop-filter: blur(8.75px);
  height: 150px;
  margin-top: -30px;
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.awards-bg .awards-list .award article h3 {
  color: #FFF;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: 30px; /* 150% */
  letter-spacing: -0.4px;
  text-transform: capitalize;
}

.awards-bg .awards-list .award article p {
  color: var(--afaec-6, #AFAEC6);
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: -0.36px;
}

.awards-bg .button {
  width: 370px;
  height: 55px;
}

/* Tax Withdrawal */
.tax-withdrawal-bg {
  width: 100%;
  background-image: url('@/assets/svg/backgrounds/square-blocks.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.tax-withdrawal-bg .tax-withdrawal-second-bg {
  padding: 80px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 80px;
}

.tax-withdrawal-bg .tax-withdrawal-second-bg h2 {
  color: #FFF;
  text-align: center;
  font-family: var(--font-space-grotesk);
  font-size: 28px;
  font-style: normal;
  font-weight: 450;
  line-height: 50px;
  letter-spacing: -0.8px;
}

.tax-withdrawal-bg .tax-withdrawal-second-bg .bg-cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.tax-withdrawal-bg .tax-withdrawal-second-bg .bg-cards .four-cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 30px;
}

.tax-withdrawal-bg .tax-withdrawal-second-bg .bg-cards .four-cards .card {
  width: 390px;
  height: auto;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(68, 18, 132, 0.10);
  backdrop-filter: blur(8.75px);
  padding: 40px;
  text-align: left;
}

.tax-withdrawal-bg .tax-withdrawal-second-bg .bg-cards .four-cards .card h3 {
  color: #FFF;
  font-family: var(--font-space-grotesk);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.4px;
}

.tax-withdrawal-bg .tax-withdrawal-second-bg .bg-cards .four-cards .card h2 {
  color: var(--d-445-eb, #EE135B);
  font-family: var(--font-space-grotesk);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.48px;
  text-align: left;
}

.tax-withdrawal-bg .tax-withdrawal-second-bg .bg-cards .four-cards .card p {
  color: var(--afaec-6, #AFAEC6);
  font-family: var(--font-work-sans);
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: 28px;
  letter-spacing: -0.36px;
  padding-top: 10px;
}

.tax-withdrawal-bg .tax-withdrawal-second-bg .bg-cards .withdrawal-politic {
  width: 390px;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(68, 18, 132, 0.10);
  backdrop-filter: blur(8.75px);
  padding: 40px;
}

.tax-withdrawal-bg .tax-withdrawal-second-bg .bg-cards .withdrawal-politic h3 {
  color: var(--d-445-eb, #EE135B);
  font-family: var(--font-space-grotesk);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.48px;
  text-align: left;
  padding-bottom: 20px;
}

.tax-withdrawal-bg .tax-withdrawal-second-bg .bg-cards .withdrawal-politic p {
  color: var(--afaec-6, #AFAEC6);
  font-family: var(--font-work-sans);
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: 28px;
  letter-spacing: -0.36px;
  padding-top: 20px;
}

/* Download App */
.download-app-bg {
  width: 100%;
  padding: 80px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.download-app-bg .infos {
  width: 560px;
}

.download-app-bg .infos h2 {
  font-family: var(--font-space-grotesk);
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -0.8px;
  text-transform: capitalize;
  background-image: var(--primary-font-bg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.download-app-bg .infos h3 {
  color: #FFF;
  font-family: var(--font-space-grotesk);
  font-size: 22px;
  font-style: normal;
  font-weight: 450;
  line-height: 34px;
  letter-spacing: -0.48px;
  text-transform: capitalize;
  margin-top: 100px;
}

.download-app-bg .infos p {
  color: #AFAEC6;
  font-family: var(--font-work-sans);
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: 28px;
  letter-spacing: -0.36px;
  margin-top: 10px;
}

.download-app-bg .infos .download-buttons {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin-top: 80px;
  padding-top: 40px;
}

.download-app-bg .infos .download-buttons img {
  cursor: pointer;
  width: 180px;
}

.download-app-bg .infos .download-buttons .app-store {
  width: 160px;
}

.download-app-bg figure img {
  width: 100%;
}

/* Footer */
.footer {
  width: 100%;
  padding: 80px 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.footer .top {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.footer .top .left {
  width: 420px;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 200px;
}

.footer .top .left h2 {
  font-family: var(--font-space-grotesk);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -0.8px;
  text-transform: capitalize;
  background-image: var(--primary-font-bg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer .top .left .input-subscription {
  position: relative;
  display: flex;
  align-items: center;
}

.footer .top .left .input-subscription input {
  width: 350px;
  height: 50px;
  border-radius: 100px;
  border: 1px solid rgba(205, 205, 205, 0.15);
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.05);
  color: #FFF;
  padding: 0px 55px 0px 30px;
}

.footer .top .left .input-subscription input:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

.footer .top .left .input-subscription .send-button {
  position: absolute;
  right: 6px;
  background-color: rgba(144, 144, 144, 0.07);
  background-image: linear-gradient(90deg, #ee135b 0%, #f84983 50%, #ee135b 90%);
  background-position: 100% 0;
  background-size: 200% 200%;
  transition: all 0.4s ease;
  border: 1px solid var(--Linear, #F84983);
  backdrop-filter: blur(2.6500000953674316px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 100%;
  cursor: pointer;
}

.footer .top .left .input-subscription .send-button:hover {
  background-image: linear-gradient(90deg, #ee135b 50%, #f84983 70%, #ee135b 90%);
  box-shadow: 0 0px 0px 0 rgba(0, 40, 120, 0);
  background-position: 0 0;
}

.footer .top .left .input-subscription .send-button .icon {
  width: 20px;
  color: #FFF;
}

.footer .top .right {
  display: flex;
  align-items: flex-star;
  justify-content: space-between;
  gap: 50px;
}

.footer .top .right nav {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.footer .top .right nav h3 {
  color: #FFF;
  font-family: var(--font-space-grotesk);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.4px;
  text-transform: capitalize;
}

.footer .top .right nav p {
  color: var(--afaec-6, #AFAEC6);
  font-family: var(--font-work-sans);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.36px;
  transition: .2s;
}

.footer .top .right nav p:hover {
  transition: .2s;
  color: var(--principal-color);
}

.footer .bottom {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 100px;
  border-top: 1px solid rgb(43, 43, 43);
  padding-top: 40px;
}

.footer .bottom p {
  width: 100%;
  color:  #AFAEC6;
  font-family: var(--font-work-sans);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: -0.36px;
}

.footer .bottom .social-infos {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer .bottom .social-infos .social {
  border-radius: 7px;
  background: #ffffff1b;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .2s;
}

.footer .bottom .social-infos .social:hover {
  border-radius: 7px;
  background: var(--principal-color);
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s;
}

@media screen and (max-width: 1280px) {
  /* Why Us */
  .why-us-bg .why-us .cards-list {
    grid-template-columns: repeat(2, 3fr);
  }

  .why-us-bg .why-us .cards-list .card {
    width: 100%;
  }

  .why-us-bg .why-us .button {
    width: 100%;
  }

  /* Awards */
  .awards-bg .awards-list {
    grid-template-columns: repeat(2, 3fr);
  }

  .awards-bg .button {
    width: 100%;
  }

  /* Tax Withdrawal */
  .tax-withdrawal-bg .tax-withdrawal-second-bg .bg-cards {
    grid-template-columns: 1fr;
  }

  .tax-withdrawal-bg .tax-withdrawal-second-bg .bg-cards .four-cards .card,
  .tax-withdrawal-bg .tax-withdrawal-second-bg .bg-cards .withdrawal-politic {
    width: 100%;
  }
}

@media screen and (max-width: 1125px) {
  /* Header */
  .header-page .bg-content-header {
    flex-direction: column;
  }

  .header-page .bg-content-header .bg-content {
    max-width: 100%;
  }

  .header-page .bg-content-header .bg-content h1 {
    margin-top: 30px;
  }

  .header-page .bg-content-header .bg-figure {
    margin-right: 0px;
    overflow-x: hidden;
    max-width: 100%;
  }

  .header-page .bg-content-header .bg-figure .bg-notifications {
    right: 40px;
    bottom: 100px;
  }

  .header-page .bg-content-header .bg-figure .bg-notifications .notifications {
    height: 400px;
  }

  /* Four steps to sale */
  .four-steps-to-sale {
    flex-direction: column;
  }

  .four-steps-to-sale article {
    width: 100%;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .four-steps-to-sale article h2 {
    font-size: 30px;
    text-align: center;
  }

  .four-steps-to-sale article .steps {
    width: 100%;
    align-items: center;
  }

  .four-steps-to-sale article .steps .step .step-article h3 {
    font-size: 20px;
  }

  .four-steps-to-sale article .steps .step .step-article p {
    font-size: 16px;
  }

  .four-steps-to-sale .future-people img {
    max-width: 100%;
  }

  /* Why Us */
  .why-us-bg .why-us .cards-list {
    width: 100%;
    grid-template-columns: 1fr;
  }

  .why-us-bg .rounded-red-blur {
    overflow: hidden;
    left: -400px;
  }

  /* Awards */
  .awards-bg .awards-list {
    grid-template-columns: 1fr;
  }

  /* Tax Withdrawal */
  .tax-withdrawal-bg .tax-withdrawal-second-bg .bg-cards .four-cards {
    grid-template-columns: 1fr;
  }

  /* Download App */
  .download-app-bg {
    flex-direction: column;
  }

  .download-app-bg .infos {
    width: 100%;
  }

  .download-app-bg figure {
    width: 100%;
    margin-top: 50px;
  }
}

@media screen and (max-width: 920px) {
  /* Footer */
  .footer .top {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
  }

  .footer .top .left {
    align-items: center;
  }

  .footer .top .left h2 {
    text-align: center;
  }

  .footer .top .left .input-subscription,
  .footer .top .left .input-subscription input {
    width: 100%;
  }

  .footer .bottom {
    flex-direction: column;
    gap: 40px;
    align-items: center;
    text-align: center;
  }
}
</style>