<script>
import Button from '@/components/Button.vue';

export default {
    data() {
        return {
            mobileMenuIsActive: false,
        }
    },
    methods: {
        redirectTo(to) {
            switch (to) {
                case 'home':
                    this.$router.push('/');
                    break;

                case 'register':
                    this.$emit('clickUserAction');
                    break;
                    
                case 'login':
                    this.$emit('clickUserAction');
                    break;
                    
                case 'porque-nos':
                    this.$emit('behaviorTo', 'porque-nos');
                    break;
                    
                case 'aprenda-a-vender':
                    this.$emit('behaviorTo', 'aprenda-a-vender');
                    break;
                    
                case 'premios':
                    this.$emit('behaviorTo', 'premios');
                    break;
                    
                case 'taxas':
                    this.$emit('behaviorTo', 'taxas');
                    break;
            }
        },
        openMenu() {
            const mobileMenu = document.getElementById('mobile-menu');

            if (mobileMenu.classList.contains('open')) {
                mobileMenu.classList.remove('open');
            } else {
                mobileMenu.classList.add('open');
            }
        }
    },
    components: { Button }
}
</script>

<template>
    <nav class="bg-nav">
        <figure @click="redirectTo('home')">
            <img src="@/assets/svg/logos/flame-logo.svg" alt="Logo Flame Pay">
        </figure>

        <!-- Desktop -->
        <section class="nav-links">
            <router-link to="/#porque-nos" @click="redirectTo('porque-nos')">
                <p>Por que nós?</p>
            </router-link>
            <router-link to="/#aprenda-a-vender" @click="redirectTo('aprenda-a-vender')">
                <p>Aprenda a vender</p>
            </router-link>
            <router-link to="/#premios" @click="redirectTo('premios')">
                <p>Prêmios</p>
            </router-link>
            <router-link to="/#taxas" @click="redirectTo('taxas')">
                <p>Taxas</p>
            </router-link>
            <router-link to="" @click="redirectTo('register')">
                <p>Cadastro</p>
            </router-link>
        </section>

        <section class="bg-button">
            <Button @click="redirectTo('login')" :colorful="false" text="Acessar" />
        </section>

        <!-- Mobile -->
        <section @click="openMenu" class="open-mobile-menu">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
            </svg>
        </section>
    </nav>

    <section id="mobile-menu" class="mobile-menu">
        <router-link to="/#porque-nos">
            <p>Por que nós?</p>
        </router-link>
        <router-link to="/#aprenda-a-vender">
            <p>Aprenda a vender</p>
        </router-link>
        <router-link to="/#premios">
            <p>Prêmios</p>
        </router-link>
        <router-link to="/#taxas">
            <p>Taxas</p>
        </router-link>
        <router-link to="" @click="redirectTo('register')">
            <p>Cadastro</p>
        </router-link>
        <router-link to="" @click="redirectTo('login')">
            <p class="access">Acessar</p>
        </router-link>
    </section>
</template>

<style scoped>
.bg-nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 19;
}

.bg-nav figure img {
    cursor: pointer;
}

.bg-nav .nav-links {
    display: flex;
    align-items: center;
    gap: 40px;
}

.bg-nav .nav-links p {
    color: #FFF;
    font-family: var(--font-work-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.64px;
    transition: .2s;
}

.bg-nav .nav-links p:hover {
    color: var(--principal-color);
    transition: .2s;
}

.bg-nav .bg-button {
    width: 160px;
    height: 48px;
}

.bg-nav .open-mobile-menu {
    display: none;
}

.mobile-menu {
    display: none;
}

@media screen and (max-width: 980px) {
    .bg-nav figure img {
        width: 130px;
    }

    .bg-nav .nav-links,
    .bg-nav .bg-button {
        display: none;
    }

    .bg-nav .open-mobile-menu {
        display: flex;
    }

    .bg-nav .open-mobile-menu .icon {
        width: 30px;
        color: white;
    }

    .mobile-menu {
        width: 100%;
        background-color: #101010;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 20px;
        border-radius: 5px;
        position: absolute;
        height: 0px;
        overflow: hidden;
        transition: height 0.4s ease;
        padding: 0px 20px;
        left: 0px;
    }

    #mobile-menu.open {
        height: 264px;
    }

    .mobile-menu p {
        color: #FFF;
        font-family: var(--font-work-sans);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        transition: .2s;
    }

    .mobile-menu p:hover {
        color: var(--principal-color);
        transition: .2s;
    }

    .mobile-menu .access {
        color: var(--principal-color) !important;
    }
}
</style>