<script>
import Button from './Button.vue';

export default {
    data() {
        return {
            email: '',
        }
    },
    methods: {
        emitDonate() {
            return this.$emit('donate', this.email);
        },
        clickClose() {
            return this.$emit('close');
        }
    },
    components: { Button }
}
</script>

<template>
    <section class="bg-loader">
        <div @click="clickClose" class="close-bg"></div>

        <section class="bg-waiting">
            <figure>
                <img src="@/assets/png/illustrations/heart.png" alt="LANÇAMENTO EM BREVE FLAME PAY">
            </figure>

            <h2>AJUDE UMA CRIANÇA COM CÂNCER</h2>
            <h2>E APRENDA A VENDER</h2>

            <p>Ajude uma criança com câncer com apenas <b>R$ 10,00</b> e aprenda gratuitamente a vender através da internet!</p>

            <div class="button">
                <Button @click="emitDonate" text="Quero ajudar com R$ 10,00 " />
            </div>
        </section>
    </section>
</template>

<style scoped>
.bg-loader {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 20;
    background-color: #000000df;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: re;
}

.close-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.bg-waiting {
    max-width: 98%;
    width: 700px;
    height: 500px;
    background-color: #111111e5;
    backdrop-filter: blur(2.6500000953674316px);
    padding: 18px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    z-index: 2;
}

.bg-waiting figure img {
    width: 130px;
    margin-bottom: 20px;
}

.bg-loader h2 {
    max-width: 98%;
    font-family: var(--font-montserrat);
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.8px;
    text-transform: capitalize;
    color: var(--principal-color);
}

.bg-loader p {
    width: 500px;
    max-width: 100%;
    color: #AFAEC6;
    font-family: var(--font-work-sans);
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: -0.36px;
    margin-top: 10px;
}

.bg-loader .input {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}

.bg-loader .input input {
    width: 350px;
    height: 50px;
    border-radius: 100px;
    border: 1px solid rgba(205, 205, 205, 0.15);
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.05);
    color: #FFF;
    padding: 0px 55px 0px 30px;
}

.bg-loader .input input:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.bg-loader .input .send-button {
    position: absolute;
    right: 5px;
    top: 4px;
    background-color: rgba(144, 144, 144, 0.07);
    background-image: linear-gradient(90deg, #ee135b 0%, #f84983 50%, #ee135b 90%);
    background-position: 100% 0;
    background-size: 200% 200%;
    transition: all 0.4s ease;
    border: 1px solid var(--Linear, #F84983);
    backdrop-filter: blur(2.6500000953674316px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
}

.bg-loader .input .send-button:hover {
    background-image: linear-gradient(90deg, #ee135b 50%, #f84983 70%, #ee135b 90%);
    box-shadow: 0 0px 0px 0 rgba(0, 40, 120, 0);
    background-position: 0 0;
}

.bg-loader .input .send-button .icon {
    width: 20px;
    color: #FFF;
}

.bg-loader .button {
    margin-top: 30px;
    height: 50px;
    width: 350px;
}
</style>