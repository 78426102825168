import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Flame Pay',
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const description = document.querySelector('meta[name="description"]');

  if (to.name != 'Flame Pay') {
    document.title = `${to.name}`;
  } else {
    if (to.path == '/') {
      document.title = `Transforme seu Conhecimento em Renda com a Flame Pay`;
      
      const meta = document.createElement('meta');
      meta.name = 'description';
      meta.content = `A Flame Pay oferece uma estrutura completa e gratuita para vender mais na internet. Possuímos uma Área de Membros Gratuita, Checkout de Alta Conversão, Taxas acessíveis e um sistema completo para Recuperação de Carrinhos Abandonados e Vendas Abandonadas.`;
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
  }


  next();
});

export default router
