import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { toast } from 'vue3-toastify';
import axios from 'axios'
import VueAxios from 'vue-axios'

const app = createApp(App);
app.use(router)
app.use(Vue3Toastify, {
    "autoClose": 3000,
    "position": "bottom-right",
    "transition": "bounce",
    "dangerouslyHTMLString": true
});
app.config.globalProperties.$axios = axios
app.use(VueAxios, axios)
app.config.globalProperties.$notification = function(text, type) {
    toast(text, {
        "type": type == 'info' ? 'info' : type == 'warning' ? 'warning' : type == 'error' ? 'error' : type == 'success' ? 'success' : 'default',
    });
}
app.mount('#app');
